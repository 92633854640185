import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <section className="bg-dark pt-8 pb-4 light">
                <div className="container">
                    <div className="position-absolute btn-back-to-top bg-dark">
                        <a className="text-600" href="#banner" data-bs-offset-top="0" data-scroll-to="#banner">
                            <span className="fas fa-chevron-up" data-fa-transform="rotate-45"></span>
                        </a>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <h5 className="text-uppercase text-white opacity-85 mb-3">Our Mission</h5>
                            <p className="text-600">Our mission here at B.R.A.D. Software is to create the easiest user exprience for you to run your business. From small to large businesses we do it all, for any and every company out there. Our goal is to have your business succeed and grow, without all the hassle that comes along with it. </p>
                            <div className="icon-group mt-4">
                                <a className="icon-item bg-white text-facebook" href="https://www.facebook.com/BRAD-Software-104776075219517/">
                                    <span className="fab fa-facebook-f"></span>
                                </a>
                                <a className="icon-item bg-white text-twitter" href="https://twitter.com/BradSoftware">
                                    <span className="fab fa-twitter"></span>
                                </a>
                                <a className="icon-item bg-white text-google-plus" href="https://www.youtube.com/channel/UCrZUnz2rSxRN7cfakXwYr-Q">
                                    <span className="fab fa-youtube"></span>
                                </a>
                                <a className="icon-item bg-white text-linkedin" href="https://www.linkedin.com/in/brad-software-902635219">
                                    <span className="fab fa-linkedin-in"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col ps-lg-6 ps-xl-8">
                            <div className="row mt-5 mt-lg-0">
                                <div className="col-6 col-md-3">
                                    <h5 className="text-uppercase text-white opacity-85 mb-3">Company</h5>
                                    <ul className="list-unstyled">
                                        <li className="mb-1">
                                            <Link className="link-600" to="/terms">Terms of Service</Link>
                                        </li>
                                        <li className="mb-1">
                                            <Link className="link-600" to="/privacy">Privacy Policy</Link>
                                        </li>
                                        <li>
                                            <Link className="link-600" to="/brand">Brand Identity</Link>
                                        </li>
                                        <li className="mb-1 mt-2">
                                            <div id="google_translate_element"></div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-6 col-md-3">
                                    <h5 className="text-uppercase text-white opacity-85 mb-3">System</h5>
                                    <ul className="list-unstyled">
                                        <li className="mb-1">
                                            <Link className="link-600" to="/status">Overall Status</Link>
                                        </li>
                                        <li className="mb-1">
                                            <Link className="link-600" to="/status">Backup Status</Link>
                                        </li>
                                        <li className="mb-1">
                                            <Link className="link-600" to="/status">Remote Status</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col mt-5 mt-md-0">
                                    <h5 className="text-uppercase text-white opacity-85 mb-3">Statistics</h5>
                                    <ul className="list-unstyled">
                                        <li className="mb-1">
                                            <a className="link-600" href="#!">Online now: <strong>4</strong></a>
                                        </li>
                                        <li className="mb-1">
                                            <a className="link-600" href="#!">Peak visitors: <strong>13</strong></a>
                                        </li>
                                        <li className="mb-1">
                                            <a className="link-600" href="#!">Total visitors: <strong>302</strong></a>
                                        </li>
                                        <li className="mb-1">
                                            <a className="link-600" href="#!">Total views: <strong>1021</strong></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-0 bg-dark light">
                <div>
                    <hr className="my-0 text-600 opacity-25" />
                    <div className="container py-3">
                        <div className="row justify-content-between fs--1">
                            <div className="col-12 col-sm-auto text-center">
                                <p className="mb-0 text-600">
                                    2021 &copy; <a className="text-white" href="https://brad.software">B.R.A.D. Software, LLC</a> <span className="d-none d-sm-inline-block">| </span><br className="d-sm-none" /> We <i className="fa fa-heart text-danger"></i> Your Business
                                </p>
                            </div>
                            <div className="col-12 col-sm-auto text-center">
                                <p className="mb-0 text-600">v2.0.1</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>          
            <script type="text/javascript" src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>
        </>
    )
}

export default Footer