const Reviews = () => {
    return (
        <>
            <section className="bg-200 text-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9 col-xl-8">
                            <div className="swiper-container theme-slider" data-swiper='{"autoplay":true,"spaceBetween":5,"loop":false,"loopedSlides":5,"slideToClickedSlide":true}'>
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <div className="px-5 px-sm-6">
                                            <p className="fs-sm-1 fs-md-2 fst-italic text-dark">I want to say that B.R.A.D. software, The Business Resources Access Database is one of the easiest and quickest systems I have ever used in my career. I am still in awe over how you can manage your contacts, calls, calendar and inventory with different categories. Even my customers can even load up items by desktop or mobile device!</p>
                                            <p className="fs-0 text-600">- Raymond Henry, Owner (I.R.A.Y. Companies)</p>
                                            <img className="w-auto mx-auto" src="https://brad.software/assets/img/logos/IRAY.png" alt="I.R.A.Y. Logo" width="150" height="50" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-nav">
                                    <div className="swiper-button-next swiper-button-white"></div>
                                    <div className="swiper-button-prev swiper-button-white"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Reviews