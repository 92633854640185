const Projects = () => {
    return (
        <>
            <div className="row gx-0 kanban-header rounded-2 px-card py-2 mt-2 mb-3">
                <div className="col d-flex align-items-center">
                    <h5 className="mb-0">B.R.A.D.</h5>
                    <button className="btn btn-sm btn-brad-default ms-3">
                        <span className="far fa-star"></span>
                    </button>
                    <div className="vertical-line vertical-line-400 position-relative h-100 mx-3"></div>
                    <ul className="nav avatar-group mb-0 d-none d-md-flex">
                        <li className="nav-item dropdown"></li>
                        <li className="nav-item dropdown">
                            <a className="nav-link p-0 dropdown-toggle dropdown-caret-none" href="#" role="button" data-bs-toggle="dropdown">
                                <div className="avatar avatar-l">
                                    <img className="rounded-circle" src="../assets/img/team/1.jpg" alt="" />
                                </div>
                            </a>
                            <div className="dropdown-menu dropdown-md px-0 py-3">
                                <div className="d-flex align-items-center position-relative px-3">
                                    <div className="avatar avatar-2xl me-2">
                                        <img className="rounded-circle" src="../assets/img/team/1.jpg" alt="" />
                                    </div>
                                    <div className="flex-1">
                                        <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">Anna Karinina</a></h6>
                                        <p className="mb-0 fs--2">Member</p>
                                    </div>
                                </div>
                                <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                            </div>
                        </li>
                        <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                            <div className="avatar avatar-l">
                                <img className="rounded-circle" src="../assets/img/team/2.jpg" alt="" />
                            </div>
                        </a>
                            <div className="dropdown-menu dropdown-md px-0 py-3">
                                <div className="d-flex align-items-center position-relative px-3">
                                    <div className="avatar avatar-2xl me-2">
                                        <img className="rounded-circle" src="../assets/img/team/2.jpg" alt="" />
                                    </div>
                                    <div className="flex-1">
                                        <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">Logan M. Butler</a></h6>
                                        <p className="mb-0 fs--2">Member</p>
                                    </div>
                                </div>
                                <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                            </div>
                        </li>
                        <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                            <div className="avatar avatar-l">
                                <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                            </div>
                        </a>
                            <div className="dropdown-menu dropdown-md px-0 py-3">
                                <div className="d-flex align-items-center position-relative px-3">
                                    <div className="avatar avatar-2xl me-2">
                                        <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                                    </div>
                                    <div className="flex-1">
                                        <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">Rowan Atkinson</a></h6>
                                        <p className="mb-0 fs--2">Member</p>
                                    </div>
                                </div>
                                <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                            </div>
                        </li>
                        <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                            <div className="avatar avatar-l">
                                <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                            </div>
                        </a>
                            <div className="dropdown-menu dropdown-md px-0 py-3">
                                <div className="d-flex align-items-center position-relative px-3">
                                    <div className="avatar avatar-2xl me-2">
                                        <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                    </div>
                                    <div className="flex-1">
                                        <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">John Doe</a></h6>
                                        <p className="mb-0 fs--2">Member</p>
                                    </div>
                                </div>
                                <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                            </div>
                        </li>
                        <li className="nav-item dropdown"><a className="nav-link dropdown-toggle dropdown-caret-none p-0 ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                            <div className="avatar avatar-l">
                                <div className="avatar-name rounded-circle avatar-button"><span>2+</span></div>
                            </div>
                        </a>
                            <div className="dropdown-menu dropdown-md">
                                <h6 className="dropdown-header py-0 px-3 mb-0">Board Members</h6>
                                <div className="dropdown-divider"></div>
                                <div className="d-flex px-3"><a className="me-2" href="../pages/user/profile.html" data-bs-toggle="tooltip" title="Anna Karinina">
                                    <div className="avatar avatar-xl">
                                        <img className="rounded-circle" src="../assets/img/team/1.jpg" alt="" />
                                    </div>
                                </a><a className="me-2" href="../pages/user/profile.html" data-bs-toggle="tooltip" title="Logan M. Butler">
                                        <div className="avatar avatar-xl">
                                            <img className="rounded-circle" src="../assets/img/team/2.jpg" alt="" />
                                        </div>
                                    </a><a className="me-2" href="../pages/user/profile.html" data-bs-toggle="tooltip" title="Rowan Atkinson">
                                        <div className="avatar avatar-xl">
                                            <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                                        </div>
                                    </a><a className="me-2" href="../pages/user/profile.html" data-bs-toggle="tooltip" title="John Doe">
                                        <div className="avatar avatar-xl">
                                            <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                        </div>
                                    </a><a className="me-2" href="../pages/user/profile.html" data-bs-toggle="tooltip" title="Emily Rose">
                                        <div className="avatar avatar-xl">
                                            <img className="rounded-circle" src="../assets/img/team/5.jpg" alt="" />
                                        </div>
                                    </a><a href="../pages/user/profile.html" data-bs-toggle="tooltip" title="Marry Jane">
                                        <div className="avatar avatar-xl">
                                            <img className="rounded-circle" src="../assets/img/team/6.jpg" alt="" />
                                        </div>
                                    </a></div>
                            </div>
                        </li>
                    </ul>
                    <div className="vertical-line vertical-line-400 position-relative h-100 mx-3 d-none d-md-flex"></div>
                    <div className="position-relative"><button className="btn btn-sm btn-brad-default dropdown-toggle dropdown-caret-none" id="invitePeople" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-plus me-2"></span>Invite</button>
                        <div className="dropdown-menu dropdown-menu-end pt-2 pb-0" aria-labelledby="invitePeople" style={{ minWidth: '300px' }}>
                            <h6 className="dropdown-header text-center text-uppercase py-1 fs--2 ls fw-semi-bold">Invite To Board</h6>
                            <div className="dropdown-divider mb-0"></div>
                            <div className="p-3">
                                <form>
                                    <div className="border rounded-1 fs--2 mb-3">
                                        <div className="d-flex flex-between-center border-bottom bg-200">
                                            <div className="px-2 text-700">Anyone with the link can join</div>
                                            <div className="border-start"><button className="btn btn-link btn-sm text-decoration-none hover-300 rounded-0 fs--2" id="dataCopy" type="button" data-copy="#invite-link" data-bs-toggle="tooltip" data-bs-trigger="manual" title="Copy to clipboard"> <span className="far fa-copy me-2"></span>Copy link</button></div>
                                        </div><input className="form-control bg-white dark__bg-dark border-0 fs--2 px-1 rounded-top-0" id="invite-link" type="text" readOnly value="https://brad.software/brad/kanban/QhNCShh8TdxKx0kYN1oWzzKJDjOYUXhm9IJ035laUVdWMYsUN5" />
                                    </div><input className="form-control form-control-sm" type="text" placeholder="Enter name or email" /><button className="btn btn-primary btn-sm d-block w-100 mt-2" type="button">Send Invitation</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto d-flex align-items-center"><button className="btn btn-sm btn-brad-default me-2 d-none d-md-block"><span className="fas fa-plus me-2"></span>Add Column</button>
                    <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default dropdown-toggle dropdown-caret-none" type="button" id="kanbanMenu" data-bs-toggle="dropdown" data-boundary="viewport" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h"></span></button>
                        <div className="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="kanbanMenu"><a className="dropdown-item" href="#!">Copy link</a>
                            <div className="dropdown-divider"></div><a className="dropdown-item" href="#!">Settings</a><a className="dropdown-item" href="#!">Themes</a>
                            <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="kanban-container scrollbar me-n3">
                <div className="kanban-column">
                    <div className="kanban-column-header">
                        <h5 className="fs-0 mb-0">Documentation <span className="text-500">(8)</span></h5>
                        <div className="dropdown font-sans-serif btn-reveal-trigger"><button className="btn btn-sm btn-reveal py-0 px-2" type="button" id="kanbanColumn0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h"></span></button>
                            <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="kanbanColumn0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                            </div>
                        </div>
                    </div>
                    <div className="kanban-items-container scrollbar">
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-1">👌 <strong>Drag cards</strong> to any list and place anywhere in the list</p>
                                </div>
                            </div>
                        </div>
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-1">👇 <strong>Click</strong> cards to see the detail of the cards or edit them</p>
                                </div>
                            </div>
                        </div>
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-1">➕ <strong>Click "Add Another Card" </strong> at the bottom of the list for a new card</p>
                                </div>
                            </div>
                        </div>
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-1"><strong>Hovering on the cards</strong> will reveal meatball (...) button, click that for more options</p>
                                </div>
                            </div>
                        </div>
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-1"><strong>At the top of the board, click ⭐</strong> to pin this board to your favorites </p>
                                </div>
                            </div>
                        </div>
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-1">🙋 Add members to the board by clicking <strong>+ Invite</strong> at the top of the board</p>
                                </div>
                            </div>
                        </div>
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-1">📃 Add more lists to this board by clicking <strong>+ Add Another List</strong></p>
                                </div>
                            </div>
                        </div>
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-1"><strong>Click the meatball (...)</strong> button at the top of any list for more options</p>
                                </div>
                            </div>
                        </div>
                        <form className="add-card-form mt-3"><textarea className="form-control" data-input="add-card" rows={2} placeholder="Enter a title for this card..."></textarea>
                            <div className="row gx-2 mt-2">
                                <div className="col"><button className="btn btn-primary btn-sm d-block w-100" type="button">Add</button></div>
                                <div className="col"><button className="btn btn-outline-secondary btn-sm d-block w-100 border-400" type="button" data-btn-form="hide">Cancel</button></div>
                            </div>
                        </form>
                    </div>
                    <div className="kanban-column-footer"><button className="btn btn-link btn-sm d-block w-100 btn-add-card text-decoration-none text-600" type="button"><span className="fas fa-plus me-2"></span>Add another card</button></div>
                </div>
                <div className="kanban-column">
                    <div className="kanban-column-header">
                        <h5 className="fs-0 mb-0">Doing <span className="text-500">(4)</span></h5>
                        <div className="dropdown font-sans-serif btn-reveal-trigger"><button className="btn btn-sm btn-reveal py-0 px-2" type="button" id="kanbanColumn1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h"></span></button>
                            <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="kanbanColumn1"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                            </div>
                        </div>
                    </div>
                    <div className="kanban-items-container scrollbar">
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-1">Add a cookie notice, which will be shown at the bottom of the page and has a link of "Privacy Policy"</p>
                                    <div className="kanban-item-footer cursor-default">
                                        <div className="text-500 z-index-2"><span className="me-2" data-bs-toggle="tooltip" title="You're assigned in this card"><span className="fas fa-eye"></span></span><span className="me-2" data-bs-toggle="tooltip" title="Checklist"><span className="fas fa-check me-1"></span><span>3/6</span></span></div>
                                        <div className="z-index-2">
                                            <div className="avatar avatar-l align-top ms-n2" data-bs-toggle="tooltip" title="Emma">
                                                <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="position-relative rounded-top-lg overflow-hidden py-8">
                                    <div className="bg-holder" style={{ backgroundImage: 'https://brad.software/assets/img/kanban/2.jpg' }}></div>
                                </div>
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-3">Add a pdf file that describes all the symptoms of COVID-19</p>
                                    <div className="kanban-item-footer cursor-default">
                                        <div className="text-500 z-index-2"><span className="me-2" data-bs-toggle="tooltip" title="Attachments"><span className="fas fa-paperclip me-1"></span><span>1</span></span></div>
                                        <div className="z-index-2">
                                            <div className="avatar avatar-l align-top ms-n2" data-bs-toggle="tooltip" title="Shophie">
                                                <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                                            </div>
                                            <div className="avatar avatar-l align-top ms-n2" data-bs-toggle="tooltip" title="Rowan">
                                                <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-2"><span className="badge py-1 me-1 mb-1 badge-soft-success">New</span><span className="badge py-1 me-1 mb-1 badge-soft-primary">Goal</span></div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-1">Make a Registration form that includes Name, Email, and a Password input field</p>
                                    <div className="kanban-item-footer cursor-default">
                                        <div className="text-500 z-index-2"><span className="me-2" data-bs-toggle="tooltip" title="Attachments"><span className="fas fa-paperclip me-1"></span><span>1</span></span><span className="me-2" data-bs-toggle="tooltip" title="Checklist"><span className="fas fa-check me-1"></span><span>2/10</span></span></div>
                                        <div className="z-index-2">
                                            <div className="avatar avatar-l align-top ms-n2" data-bs-toggle="tooltip" title="Shophie">
                                                <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-2"><span className="badge py-1 me-1 mb-1 badge-soft-info">Enhancement</span></div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-1">Update profile page layout with cover image and user setting menu</p>
                                    <div className="kanban-item-footer cursor-default">
                                        <div className="text-500 z-index-2"><span className="me-2" data-bs-toggle="tooltip" title="You're assigned in this card"><span className="fas fa-eye"></span></span><span className="me-2" data-bs-toggle="tooltip" title="Attachments"><span className="fas fa-paperclip me-1"></span><span>1</span></span></div>
                                        <div className="z-index-2">
                                            <div className="avatar avatar-l align-top ms-n2" data-bs-toggle="tooltip" title="Emma">
                                                <img className="rounded-circle" src="../assets/img/team/1.jpg" alt="" />
                                            </div>
                                            <div className="avatar avatar-l align-top ms-n2" data-bs-toggle="tooltip" title="Antony">
                                                <img className="rounded-circle" src="../assets/img/team/2.jpg" alt="" />
                                            </div>
                                            <div className="avatar avatar-l align-top ms-n2" data-bs-toggle="tooltip" title="Anna">
                                                <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form className="add-card-form mt-3"><textarea className="form-control" data-input="add-card" rows={2} placeholder="Enter a title for this card..."></textarea>
                            <div className="row gx-2 mt-2">
                                <div className="col"><button className="btn btn-primary btn-sm d-block w-100" type="button">Add</button></div>
                                <div className="col"><button className="btn btn-outline-secondary btn-sm d-block w-100 border-400" type="button" data-btn-form="hide">Cancel</button></div>
                            </div>
                        </form>
                    </div>
                    <div className="kanban-column-footer"><button className="btn btn-link btn-sm d-block w-100 btn-add-card text-decoration-none text-600" type="button"><span className="fas fa-plus me-2"></span>Add another card</button></div>
                </div>
                <div className="kanban-column">
                    <div className="kanban-column-header">
                        <h5 className="fs-0 mb-0">Review <span className="text-500">(4)</span></h5>
                        <div className="dropdown font-sans-serif btn-reveal-trigger"><button className="btn btn-sm btn-reveal py-0 px-2" type="button" id="kanbanColumn2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h"></span></button>
                            <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="kanbanColumn2"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                            </div>
                        </div>
                    </div>
                    <div className="kanban-items-container scrollbar">
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-2"><span className="badge py-1 me-1 mb-1 badge-soft-danger">bug</span></div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-1">Update all the npm packages and also remove the outdated plugins</p>
                                    <div className="kanban-item-footer cursor-default">
                                        <div className="text-500 z-index-2"><span className="me-2" data-bs-toggle="tooltip" title="You're assigned in this card"><span className="fas fa-eye"></span></span><span className="me-2" data-bs-toggle="tooltip" title="Checklist"><span className="fas fa-check me-1"></span><span>5/5</span></span></div>
                                        <div className="z-index-2">
                                            <div className="avatar avatar-l align-top ms-n2" data-bs-toggle="tooltip" title="Sophie">
                                                <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                            </div>
                                            <div className="avatar avatar-l align-top ms-n2" data-bs-toggle="tooltip" title="Antony">
                                                <img className="rounded-circle" src="../assets/img/team/1.jpg" alt="" />
                                            </div>
                                            <div className="avatar avatar-l align-top ms-n2" data-bs-toggle="tooltip" title="Emma">
                                                <img className="rounded-circle" src="../assets/img/team/2.jpg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-2"><span className="badge py-1 me-1 mb-1 badge-soft-secondary">Documentation</span></div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-1">Add a getting started page that allows users to see the starting process</p>
                                    <div className="kanban-item-footer cursor-default">
                                        <div className="text-500 z-index-2"><span className="me-2" data-bs-toggle="tooltip" title="Attachments"><span className="fas fa-paperclip me-1"></span><span>2</span></span></div>
                                        <div className="z-index-2">
                                            <div className="avatar avatar-l align-top ms-n2" data-bs-toggle="tooltip" title="Antony">
                                                <img className="rounded-circle" src="../assets/img/team/2.jpg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-1">Review and test all the task and deploy to the server</p>
                                </div>
                            </div>
                        </div>
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-2"><span className="badge py-1 me-1 mb-1 badge-soft-success">New</span></div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-1">Get all the data by API call and show them to the landing page by adding a new section</p>
                                </div>
                            </div>
                        </div>
                        <form className="add-card-form mt-3"><textarea className="form-control" data-input="add-card" rows={2} placeholder="Enter a title for this card..."></textarea>
                            <div className="row gx-2 mt-2">
                                <div className="col"><button className="btn btn-primary btn-sm d-block w-100" type="button">Add</button></div>
                                <div className="col"><button className="btn btn-outline-secondary btn-sm d-block w-100 border-400" type="button" data-btn-form="hide">Cancel</button></div>
                            </div>
                        </form>
                    </div>
                    <div className="kanban-column-footer"><button className="btn btn-link btn-sm d-block w-100 btn-add-card text-decoration-none text-600" type="button"><span className="fas fa-plus me-2"></span>Add another card</button></div>
                </div>
                <div className="kanban-column">
                    <div className="kanban-column-header">
                        <h5 className="fs-0 mb-0">Release <span className="text-500">(5)</span></h5>
                        <div className="dropdown font-sans-serif btn-reveal-trigger"><button className="btn btn-sm btn-reveal py-0 px-2" type="button" id="kanbanColumn3" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h"></span></button>
                            <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="kanbanColumn3"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                            </div>
                        </div>
                    </div>
                    <div className="kanban-items-container scrollbar">
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="position-relative rounded-top-lg overflow-hidden py-9">
                                    <div className="bg-holder" style={{ backgroundImage: 'https://brad.software/assets/img/kanban/1.jpg' }}></div>
                                </div>
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-2">Add a new illustration to the landing page according to the contrast of the current theme </p>
                                    <div className="kanban-item-footer cursor-default">
                                        <div className="text-500 z-index-2"><span className="me-2" data-bs-toggle="tooltip" title="You're assigned in this card"><span className="fas fa-eye"></span></span><span className="me-2" data-bs-toggle="tooltip" title="Attachments"><span className="fas fa-paperclip me-1"></span><span>2</span></span></div>
                                        <div className="z-index-2">
                                            <div className="avatar avatar-l align-top ms-n2" data-bs-toggle="tooltip" title="Anna">
                                                <img className="rounded-circle" src="../assets/img/team/1.jpg" alt="" />
                                            </div>
                                            <div className="avatar avatar-l align-top ms-n2" data-bs-toggle="tooltip" title="Antony">
                                                <img className="rounded-circle" src="../assets/img/team/2.jpg" alt="" />
                                            </div>
                                            <div className="avatar avatar-l align-top ms-n2" data-bs-toggle="tooltip" title="Emma">
                                                <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-2"><span className="badge py-1 me-1 mb-1 badge-soft-info">Goal</span></div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-1">Design a new E-commerce, Product list, and details page</p>
                                </div>
                            </div>
                        </div>
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-1">Make a weather app design which must have: Local weather, Weather map and weather widgets</p>
                                </div>
                            </div>
                        </div>
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-2"><span className="badge py-1 me-1 mb-1 badge-soft-secondary">Documentation</span></div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-1">List all the Frequently Asked Questions and make an FAQ section in the landing page</p>
                                </div>
                            </div>
                        </div>
                        <div className="kanban-item">
                            <div className="card kanban-item-card hover-actions-trigger">
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="dropdown font-sans-serif"><button className="btn btn-sm btn-brad-default kanban-item-dropdown-btn hover-actions" type="button" data-boundary="viewport" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h" data-fa-transform="shrink-2"></span></button>
                                            <div className="dropdown-menu dropdown-menu-end py-0"><a className="dropdown-item" href="#!">Add Card</a><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item" href="#!">Copy link</a>
                                                <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0 fw-medium font-sans-serif stretched-link" data-bs-toggle="modal" data-bs-target="#kanban-modal-1">Remove all the warning from dev dependencies and update the packages if needed</p>
                                </div>
                            </div>
                        </div>
                        <form className="add-card-form mt-3"><textarea className="form-control" data-input="add-card" rows={2} placeholder="Enter a title for this card..."></textarea>
                            <div className="row gx-2 mt-2">
                                <div className="col"><button className="btn btn-primary btn-sm d-block w-100" type="button">Add</button></div>
                                <div className="col"><button className="btn btn-outline-secondary btn-sm d-block w-100 border-400" type="button" data-btn-form="hide">Cancel</button></div>
                            </div>
                        </form>
                    </div>
                    <div className="kanban-column-footer"><button className="btn btn-link btn-sm d-block w-100 btn-add-card text-decoration-none text-600" type="button"><span className="fas fa-plus me-2"></span>Add another card</button></div>
                </div>
                <div className="kanban-column">
                    <div className="collapse bg-100 p-card rounded-lg transition-none" id="addListForm">
                        <form><textarea className="form-control mb-2" data-input="add-list" rows={2} placeholder="Enter list title..."></textarea>
                            <div className="row gx-2">
                                <div className="col"><button className="btn btn-primary btn-sm d-block w-100" type="button">Add</button></div>
                                <div className="col"><button className="btn btn-outline-secondary btn-sm d-block w-100 border-400" type="button" data-dismiss="collapse">Cancel</button></div>
                            </div>
                        </form>
                    </div>
                    <button className="btn d-block w-100 btn-secondary bg-400 border-400" data-bs-toggle="collapse" data-bs-target="#addListForm" aria-expanded="false" aria-controls="addListForm">
                        <span className="fas fa-plus me-1"></span>
                        Add another list
                    </button>
                </div>
            </div>
            <div className="modal fade" id="authentication-modal" tabIndex={-1} role="dialog" aria-labelledby="authentication-modal-label" aria-hidden="true">
                <div className="modal-dialog mt-6" role="document">
                    <div className="modal-content border-0">
                        <div className="modal-header px-5 position-relative modal-shape-header bg-shape">
                            <div className="position-relative z-index-1 light">
                                <h4 className="mb-0 text-white" id="authentication-modal-label">Register</h4>
                                <p className="fs--1 mb-0 text-white">Please create your free brad account</p>
                            </div><button className="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body py-4 px-5">
                            <form>
                                <div className="mb-3"><label className="form-label">Name</label><input className="form-control" type="text" autoComplete="on" id="modal-auth-name" /></div>
                                <div className="mb-3"><label className="form-label">Email address</label><input className="form-control" type="email" autoComplete="on" id="modal-auth-email" /></div>
                                <div className="row gx-2">
                                    <div className="mb-3 col-sm-6"><label className="form-label">Password</label><input className="form-control" type="password" autoComplete="on" id="modal-auth-password" /></div>
                                    <div className="mb-3 col-sm-6"><label className="form-label">Confirm Password</label><input className="form-control" type="password" autoComplete="on" id="modal-auth-confirm-password" /></div>
                                </div>
                                <div className="form-check"><input className="form-check-input" type="checkbox" id="modal-auth-register-checkbox" /><label className="form-label">I accept the <a href="#!">terms </a>and <a href="#!">privacy policy</a></label></div>
                                <div className="mb-3"><button className="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">Register</button></div>
                            </form>
                            <div className="position-relative mt-5">
                                <hr className="bg-300" />
                                <div className="divider-content-center">or register with</div>
                            </div>
                            <div className="row g-2 mt-2">
                                <div className="col-sm-6"><a className="btn btn-outline-google-plus btn-sm d-block w-100" href="#"><span className="fab fa-google-plus-g me-2" data-fa-transform="grow-8"></span> google</a></div>
                                <div className="col-sm-6"><a className="btn btn-outline-facebook btn-sm d-block w-100" href="#"><span className="fab fa-facebook-square me-2" data-fa-transform="grow-8"></span> facebook</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="kanban-modal-label-1" aria-hidden="true" id="kanban-modal-1">
                <div className="modal-dialog modal-lg mt-6" role="document">
                    <div className="modal-content border-0">
                        <div className="position-absolute top-0 end-0 mt-3 me-3 z-index-1"><button className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close"></button></div>
                        <div className="modal-body p-0">
                            <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
                                <h4 className="mb-1" id="kanban-modal-label-1">Add a new illustration to the landing page</h4>
                                <p className="fs--2 mb-0">Added by <a className="link-600 fw-semi-bold" href="#!">Antony</a></p>
                            </div>
                            <div className="p-4">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <div className="d-flex"><span className="fa-stack ms-n1 me-3"><i className="fas fa-circle fa-stack-2x text-200"></i><i className="fa-inverse fa-stack-1x text-primary fas fa-user" data-fa-transform="shrink-2"></i></span>
                                            <div className="flex-1">
                                                <h5 className="mb-2 fs-0">Reviewers</h5>
                                                <ul className="nav avatar-group mb-0">
                                                    <li className="nav-item dropdown"></li>
                                                    <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <img className="rounded-circle" src="../assets/img/team/1.jpg" alt="" />
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md px-0 py-3">
                                                            <div className="d-flex align-items-center position-relative px-3">
                                                                <div className="avatar avatar-2xl me-2">
                                                                    <img className="rounded-circle" src="../assets/img/team/1.jpg" alt="" />
                                                                </div>
                                                                <div className="flex-1">
                                                                    <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">Anna Karinina</a></h6>
                                                                    <p className="mb-0 fs--2">Member</p>
                                                                </div>
                                                            </div>
                                                            <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <img className="rounded-circle" src="../assets/img/team/2.jpg" alt="" />
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md px-0 py-3">
                                                            <div className="d-flex align-items-center position-relative px-3">
                                                                <div className="avatar avatar-2xl me-2">
                                                                    <img className="rounded-circle" src="../assets/img/team/2.jpg" alt="" />
                                                                </div>
                                                                <div className="flex-1">
                                                                    <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">Logan M. Butler</a></h6>
                                                                    <p className="mb-0 fs--2">Member</p>
                                                                </div>
                                                            </div>
                                                            <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md px-0 py-3">
                                                            <div className="d-flex align-items-center position-relative px-3">
                                                                <div className="avatar avatar-2xl me-2">
                                                                    <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                                                                </div>
                                                                <div className="flex-1">
                                                                    <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">Rowan Atkinson</a></h6>
                                                                    <p className="mb-0 fs--2">Member</p>
                                                                </div>
                                                            </div>
                                                            <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md px-0 py-3">
                                                            <div className="d-flex align-items-center position-relative px-3">
                                                                <div className="avatar avatar-2xl me-2">
                                                                    <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                                                </div>
                                                                <div className="flex-1">
                                                                    <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">John Doe</a></h6>
                                                                    <p className="mb-0 fs--2">Member</p>
                                                                </div>
                                                            </div>
                                                            <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <img className="rounded-circle" src="../assets/img/team/5.jpg" alt="" />
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md px-0 py-3">
                                                            <div className="d-flex align-items-center position-relative px-3">
                                                                <div className="avatar avatar-2xl me-2">
                                                                    <img className="rounded-circle" src="../assets/img/team/5.jpg" alt="" />
                                                                </div>
                                                                <div className="flex-1">
                                                                    <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">Emily Rose</a></h6>
                                                                    <p className="mb-0 fs--2">Member</p>
                                                                </div>
                                                            </div>
                                                            <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <img className="rounded-circle" src="../assets/img/team/6.jpg" alt="" />
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md px-0 py-3">
                                                            <div className="d-flex align-items-center position-relative px-3">
                                                                <div className="avatar avatar-2xl me-2">
                                                                    <img className="rounded-circle" src="../assets/img/team/6.jpg" alt="" />
                                                                </div>
                                                                <div className="flex-1">
                                                                    <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">Marry Jane</a></h6>
                                                                    <p className="mb-0 fs--2">Member</p>
                                                                </div>
                                                            </div>
                                                            <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown"><a className="nav-link dropdown-toggle dropdown-caret-none p-0 ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <div className="avatar-name rounded-circle avatar-button"><span><span className="fas fa-plus" data-fa-trasnsform="grow-2"></span></span></div>
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md" data-list='{"valueNames":["name"]}'>
                                                            <h6 className="dropdown-header py-0 px-3 mb-0">Select Member</h6>
                                                            <div className="dropdown-divider"></div>
                                                            <form className="px-3 mb-2"><input className="form-control form-control-sm search" type="text" placeholder="Search team member" /></form>
                                                            <ul className="list-unstyled list">
                                                                <li><a className="d-flex align-items-center text-decoration-none py-1 px-3" href="#!">
                                                                    <div className="avatar avatar-xl me-2">
                                                                        <img className="rounded-circle" src="../assets/img/team/1.jpg" alt="" />
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <h6 className="mb-0 link-900 name">Anna Karinina</h6>
                                                                    </div>
                                                                </a></li>
                                                                <li><a className="d-flex align-items-center text-decoration-none py-1 px-3" href="#!">
                                                                    <div className="avatar avatar-xl me-2">
                                                                        <img className="rounded-circle" src="../assets/img/team/2.jpg" alt="" />
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <h6 className="mb-0 link-900 name">Logan M. Butler</h6>
                                                                    </div>
                                                                </a></li>
                                                                <li><a className="d-flex align-items-center text-decoration-none py-1 px-3" href="#!">
                                                                    <div className="avatar avatar-xl me-2">
                                                                        <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <h6 className="mb-0 link-900 name">Rowan Atkinson</h6>
                                                                    </div>
                                                                </a></li>
                                                                <li><a className="d-flex align-items-center text-decoration-none py-1 px-3" href="#!">
                                                                    <div className="avatar avatar-xl me-2">
                                                                        <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <h6 className="mb-0 link-900 name">John Doe</h6>
                                                                    </div>
                                                                </a></li>
                                                                <li><a className="d-flex align-items-center text-decoration-none py-1 px-3" href="#!">
                                                                    <div className="avatar avatar-xl me-2">
                                                                        <img className="rounded-circle" src="../assets/img/team/5.jpg" alt="" />
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <h6 className="mb-0 link-900 name">Emily Rose</h6>
                                                                    </div>
                                                                </a></li>
                                                                <li><a className="d-flex align-items-center text-decoration-none py-1 px-3" href="#!">
                                                                    <div className="avatar avatar-xl me-2">
                                                                        <img className="rounded-circle" src="../assets/img/team/6.jpg" alt="" />
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <h6 className="mb-0 link-900 name">Marry Jane</h6>
                                                                    </div>
                                                                </a></li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <hr className="my-4" />
                                            </div>
                                        </div>
                                        <div className="d-flex"><span className="fa-stack ms-n1 me-3"><i className="fas fa-circle fa-stack-2x text-200"></i><i className="fa-inverse fa-stack-1x text-primary fas fa-tag" data-fa-transform="shrink-2"></i></span>
                                            <div className="flex-1">
                                                <h5 className="mb-2 fs-0">Labels</h5>
                                                <div className="d-flex"><span className="badge me-1 py-2 badge-soft-success">New</span><span className="badge me-1 py-2 badge-soft-primary">Goal</span><span className="badge me-1 py-2 badge-soft-info">Enhancement</span>
                                                    <div className="dropdown dropend"><button className="btn btn-sm btn-secondary px-2 fsp-75 bg-400 border-400 dropdown-toggle dropdown-caret-none" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-plus"></span></button>
                                                        <div className="dropdown-menu">
                                                            <h6 className="dropdown-header py-0 px-3 mb-0">Select Label</h6>
                                                            <div className="dropdown-divider"></div>
                                                            <div className="px-3"><button className="badge-soft-success dropdown-item rounded-1 mb-2" type="button">New</button><button className="badge-soft-primary dropdown-item rounded-1 mb-2" type="button">Goal</button><button className="badge-soft-info dropdown-item rounded-1 mb-2" type="button">Enhancement</button><button className="badge-soft-danger dropdown-item rounded-1 mb-2" type="button">Bug</button><button className="badge-soft-secondary dropdown-item rounded-1 mb-2" type="button">Documentation</button><button className="badge-soft-warning dropdown-item rounded-1 mb-2" type="button">Helper</button></div>
                                                            <div className="dropdown-divider"></div>
                                                            <div className="px-3"><button className="btn btn-sm d-block w-100 btn-outline-secondary border-400">Create Label</button></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="my-4" />
                                            </div>
                                        </div>
                                        <div className="d-flex"><span className="fa-stack ms-n1 me-3"><i className="fas fa-circle fa-stack-2x text-200"></i><i className="fa-inverse fa-stack-1x text-primary fas fa-align-left" data-fa-transform="shrink-2"></i></span>
                                            <div className="flex-1">
                                                <h5 className="mb-2 fs-0">Description</h5>
                                                <p className="text-word-break fs--1">The illustration must match to the contrast of the theme. The illustraion must described the concept of the design. To know more about the theme visit the page. <a href="https://brad.software/brad/v2.0.1/index.php" target="_blank">https://brad.software/brad/v2.0.1/index.php</a></p>
                                                <hr className="my-4" />
                                            </div>
                                        </div>
                                        <div className="d-flex"> <span className="fa-stack ms-n1 me-3"><i className="fas fa-circle fa-stack-2x text-200"></i><i className="fa-inverse fa-stack-1x text-primary fas fa-paperclip" data-fa-transform="shrink-2"></i></span>
                                            <div className="flex-1">
                                                <div className="d-flex justify-content-between mb-2">
                                                    <h5 className="mb-0 fs-0">Attachments</h5>
                                                    <div className="dropdown"><button className="btn btn-brad-default btn-sm dropdown-toggle dropdown-caret-none fs--2" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <span className="fas fa-plus"></span></button>
                                                        <div className="dropdown-menu dropdown-menu-end"><a className="dropdown-item" href="#!">Computer</a><a className="dropdown-item" href="#!">Google Drive</a>
                                                            <div className="dropdown-divider"></div><a className="dropdown-item" href="#!">Attach Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-3"><a className="text-decoration-none me-3" href="../assets/img/kanban/3.jpg" data-gallery="attachment-bg">
                                                    <div className="bg-attachment">
                                                        <div className="bg-holder rounded" style={{backgroundImage: 'https://brad.software/assets/img/kanban/3.jpg'}}></div>
                                                    </div>
                                                </a>
                                                    <div className="flex-1 fs--2">
                                                        <h6 className="mb-1"> <a className="text-decoration-none" href="../assets/img/kanban/3.jpg" data-gallery="attachment-title">final-img.jpg</a></h6><a className="link-600 fw-semi-bold" href="#!">Edit</a><span className="mx-1">|</span><a className="link-600 fw-semi-bold" href="#!">Remove</a>
                                                        <p className="mb-0">Uploaded at 2020-04-18 5:25 pm</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-3"><a className="text-decoration-none me-3" href="../assets/img/kanban/4.jpg" data-gallery="attachment-bg">
                                                    <div className="bg-attachment">
                                                        <div className="bg-holder rounded" style={{backgroundImage: 'https://brad.software/assets/img/kanban/4.jpg'}}></div>
                                                    </div>
                                                </a>
                                                    <div className="flex-1 fs--2">
                                                        <h6 className="mb-1"> <a className="text-decoration-none" href="../assets/img/kanban/4.jpg" data-gallery="attachment-title">picture.png</a></h6><a className="link-600 fw-semi-bold" href="#!">Edit</a><span className="mx-1">|</span><a className="link-600 fw-semi-bold" href="#!">Remove</a>
                                                        <p className="mb-0">Uploaded at 2020-04-20 4:34 pm</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-3"><a className="text-decoration-none me-3" href="#!">
                                                    <div className="bg-attachment"><span className="text-uppercase fw-bold">txt</span></div>
                                                </a>
                                                    <div className="flex-1 fs--2">
                                                        <h6 className="mb-1"> <a className="text-decoration-none" href="#!">sample.txt</a></h6><a className="link-600 fw-semi-bold" href="#!">Edit</a><span className="mx-1">|</span><a className="link-600 fw-semi-bold" href="#!">Remove</a>
                                                        <p className="mb-0">Uploaded at 2020-04-21 2:10 pm</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-3"><a className="text-decoration-none me-3" href="#!">
                                                    <div className="bg-attachment"><span className="text-uppercase fw-bold">pdf</span></div>
                                                </a>
                                                    <div className="flex-1 fs--2">
                                                        <h6 className="mb-1"> <a className="text-decoration-none" href="#!">example.pdf</a></h6><a className="link-600 fw-semi-bold" href="#!">Edit</a><span className="mx-1">|</span><a className="link-600 fw-semi-bold" href="#!">Remove</a>
                                                        <p className="mb-0">Uploaded at 2020-05-02 11:34 am</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center"><a className="text-decoration-none me-3" href="../assets/video/beach.mp4" data-gallery="attachment-bg">
                                                    <div className="bg-attachment">
                                                        <div className="bg-holder rounded" style={{backgroundImage: 'https://brad.software/assets/video/beach.jpg'}}></div>
                                                        <div className="icon-play"><span className="fas fa-play"></span></div>
                                                    </div>
                                                </a>
                                                    <div className="flex-1 fs--2">
                                                        <h6 className="mb-1"> <a className="text-decoration-none" href="../assets/video/beach.mp4" data-gallery="attachment-title">beach.mp4</a></h6><a className="link-600 fw-semi-bold" href="#!">Edit</a><span className="mx-1">|</span><a className="link-600 fw-semi-bold" href="#!">Remove</a>
                                                        <p className="mb-0">Uploaded at 2020-05-10 3:40 pm</p>
                                                    </div>
                                                </div>
                                                <hr className="my-4" />
                                            </div>
                                        </div>
                                        <div className="d-flex"><span className="fa-stack ms-n1 me-3"><i className="fas fa-circle fa-stack-2x text-200"></i><i className="fa-inverse fa-stack-1x text-primary far fa-comment" data-fa-transform="shrink-2"></i></span>
                                            <div className="flex-1">
                                                <h5 className="mb-3 fs-0">Comments</h5>
                                                <div className="d-flex">
                                                    <div className="avatar avatar-l me-2">
                                                        <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                                    </div>
                                                    <div className="flex-1 fs--1">
                                                        <div className="position-relative border rounded-1 mb-3">
                                                            <form><textarea className="form-control border-0 rounded-bottom-0 resize-none" rows={3} contentEditable="true"></textarea>
                                                                <div className="d-flex flex-between-center bg-light rounded-bottom p-2 mt-1"><button className="btn btn-sm btn-primary" type="submit">Save</button>
                                                                    <ul className="list-inline mb-0">
                                                                        <li className="list-inline-item me-1"><a className="link-600 p-2 transition-base rounded" href="#!"><span className="fas fa-paperclip"></span></a></li>
                                                                        <li className="list-inline-item me-1"><a className="link-600 p-2 transition-base rounded" href="#!"><span className="fas fa-at"></span></a></li>
                                                                        <li className="list-inline-item me-1"><a className="link-600 p-2 transition-base rounded" href="#!"><span className="fas fa-image"></span></a></li>
                                                                    </ul>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-3"><a href="../pages/user/profile.html">
                                                    <div className="avatar avatar-l">
                                                        <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                                    </div>
                                                </a>
                                                    <div className="flex-1 ms-2 fs--1">
                                                        <p className="mb-1 bg-200 rounded-3 p-2"><a className="fw-semi-bold" href="../pages/user/profile.html">Rowan </a>This time we should finish the task before the deadline</p><a href="#!">Like</a> &bull; <a href="#!">Reply</a> &bull; 23min
                                                    </div>
                                                </div>
                                                <div className="d-flex"><a href="../pages/user/profile.html">
                                                    <div className="avatar avatar-l">
                                                        <img className="rounded-circle" src="../assets/img/team/1.jpg" alt="" />
                                                    </div>
                                                </a>
                                                    <div className="flex-1 ms-2 fs--1">
                                                        <p className="mb-1 bg-200 rounded-3 p-2"><a className="fw-semi-bold" href="../pages/user/profile.html">Emma </a>We have more task to do</p><a href="#!">Like</a> &bull; <a href="#!">Reply</a> &bull; 2hour
                                                    </div>
                                                </div>
                                                <hr className="my-4" />
                                            </div>
                                        </div>
                                        <div className="d-flex"><span className="fa-stack ms-n1 me-3"><i className="fas fa-circle fa-stack-2x text-200"></i><i className="fa-inverse fa-stack-1x text-primary fas fa-list-ul" data-fa-transform="shrink-2"></i></span>
                                            <div className="flex-1">
                                                <h5 className="mb-3 fs-0">Activity </h5>
                                                <div className="d-flex mb-3"><a href="../pages/user/profile.html">
                                                    <div className="avatar avatar-l">
                                                        <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                                    </div>
                                                </a>
                                                    <div className="flex-1 ms-2 fs--1">
                                                        <p className="mb-0"><a className="fw-semi-bold" href="../pages/user/profile.html">Rowan </a>Added the card</p>
                                                        <div className="fs--2">6 hours ago</div>
                                                    </div>
                                                </div>
                                                <div className="d-flex"><a href="../pages/user/profile.html">
                                                    <div className="avatar avatar-l">
                                                        <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                                                    </div>
                                                </a>
                                                    <div className="flex-1 ms-2 fs--1">
                                                        <p className="mb-0"><a className="fw-semi-bold" href="../pages/user/profile.html">Anna </a>attached final-pic.png to this card</p>
                                                        <div className="fs--2">4 hours ago</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <h6 className="mt-5 mt-lg-0">Add To Card</h6>
                                        <ul className="nav flex-lg-column fs--1">
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"> <span className="fas fa-user me-2"></span>Members</a></li>
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"> <span className="fas fa-tag me-2"></span>Label</a></li>
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"> <span className="fas fa-paperclip me-2"></span>Attachments</a></li>
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"> <span className="fas fa-check me-2"></span>Checklists</a></li>
                                        </ul>
                                        <h6 className="mt-3">Actions</h6>
                                        <ul className="nav flex-lg-column fs--1">
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"> <span className="far fa-copy me-2"> </span>Copy</a></li>
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"> <span className="fas fa-arrow-right me-2"></span>Move</a></li>
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"><span className="fas fa-trash-alt me-2"></span>Remove</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="kanban-modal-label-2" aria-hidden="true" id="kanban-modal-2">
                <div className="modal-dialog modal-lg mt-6" role="document">
                    <div className="modal-content border-0">
                        <div className="position-relative overflow-hidden py-8">
                            <div className="bg-holder rounded-top-lg" style={{backgroundImage: 'https://brad.software/assets/img/kanban/1.jpg'}}></div>
                        </div>
                        <div className="position-absolute top-0 end-0 mt-3 me-3 z-index-1"><button className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close"></button></div>
                        <div className="modal-body p-0">
                            <div className="bg-light rounded-top-lg py-3 px-4">
                                <h4 className="mb-1" id="kanban-modal-label-2">Add a new illustration to the landing page</h4>
                                <p className="fs--2 mb-0">Added by <a className="link-600 fw-semi-bold" href="#!">Antony</a></p>
                            </div>
                            <div className="p-4">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <div className="d-flex"><span className="fa-stack ms-n1 me-3"><i className="fas fa-circle fa-stack-2x text-200"></i><i className="fa-inverse fa-stack-1x text-primary fas fa-user" data-fa-transform="shrink-2"></i></span>
                                            <div className="flex-1">
                                                <h5 className="mb-2 fs-0">Reviewers</h5>
                                                <ul className="nav avatar-group mb-0">
                                                    <li className="nav-item dropdown"></li>
                                                    <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <img className="rounded-circle" src="../assets/img/team/1.jpg" alt="" />
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md px-0 py-3">
                                                            <div className="d-flex align-items-center position-relative px-3">
                                                                <div className="avatar avatar-2xl me-2">
                                                                    <img className="rounded-circle" src="../assets/img/team/1.jpg" alt="" />
                                                                </div>
                                                                <div className="flex-1">
                                                                    <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">Anna Karinina</a></h6>
                                                                    <p className="mb-0 fs--2">Member</p>
                                                                </div>
                                                            </div>
                                                            <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <img className="rounded-circle" src="../assets/img/team/2.jpg" alt="" />
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md px-0 py-3">
                                                            <div className="d-flex align-items-center position-relative px-3">
                                                                <div className="avatar avatar-2xl me-2">
                                                                    <img className="rounded-circle" src="../assets/img/team/2.jpg" alt="" />
                                                                </div>
                                                                <div className="flex-1">
                                                                    <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">Logan M. Butler</a></h6>
                                                                    <p className="mb-0 fs--2">Member</p>
                                                                </div>
                                                            </div>
                                                            <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md px-0 py-3">
                                                            <div className="d-flex align-items-center position-relative px-3">
                                                                <div className="avatar avatar-2xl me-2">
                                                                    <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                                                                </div>
                                                                <div className="flex-1">
                                                                    <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">Rowan Atkinson</a></h6>
                                                                    <p className="mb-0 fs--2">Member</p>
                                                                </div>
                                                            </div>
                                                            <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md px-0 py-3">
                                                            <div className="d-flex align-items-center position-relative px-3">
                                                                <div className="avatar avatar-2xl me-2">
                                                                    <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                                                </div>
                                                                <div className="flex-1">
                                                                    <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">John Doe</a></h6>
                                                                    <p className="mb-0 fs--2">Member</p>
                                                                </div>
                                                            </div>
                                                            <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <img className="rounded-circle" src="../assets/img/team/5.jpg" alt="" />
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md px-0 py-3">
                                                            <div className="d-flex align-items-center position-relative px-3">
                                                                <div className="avatar avatar-2xl me-2">
                                                                    <img className="rounded-circle" src="../assets/img/team/5.jpg" alt="" />
                                                                </div>
                                                                <div className="flex-1">
                                                                    <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">Emily Rose</a></h6>
                                                                    <p className="mb-0 fs--2">Member</p>
                                                                </div>
                                                            </div>
                                                            <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <img className="rounded-circle" src="../assets/img/team/6.jpg" alt="" />
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md px-0 py-3">
                                                            <div className="d-flex align-items-center position-relative px-3">
                                                                <div className="avatar avatar-2xl me-2">
                                                                    <img className="rounded-circle" src="../assets/img/team/6.jpg" alt="" />
                                                                </div>
                                                                <div className="flex-1">
                                                                    <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">Marry Jane</a></h6>
                                                                    <p className="mb-0 fs--2">Member</p>
                                                                </div>
                                                            </div>
                                                            <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown"><a className="nav-link dropdown-toggle dropdown-caret-none p-0 ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <div className="avatar-name rounded-circle avatar-button"><span><span className="fas fa-plus" data-fa-trasnsform="grow-2"></span></span></div>
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md" data-list='{"valueNames":["name"]}'>
                                                            <h6 className="dropdown-header py-0 px-3 mb-0">Select Member</h6>
                                                            <div className="dropdown-divider"></div>
                                                            <form className="px-3 mb-2"><input className="form-control form-control-sm search" type="text" placeholder="Search team member" /></form>
                                                            <ul className="list-unstyled list">
                                                                <li><a className="d-flex align-items-center text-decoration-none py-1 px-3" href="#!">
                                                                    <div className="avatar avatar-xl me-2">
                                                                        <img className="rounded-circle" src="../assets/img/team/1.jpg" alt="" />
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <h6 className="mb-0 link-900 name">Anna Karinina</h6>
                                                                    </div>
                                                                </a></li>
                                                                <li><a className="d-flex align-items-center text-decoration-none py-1 px-3" href="#!">
                                                                    <div className="avatar avatar-xl me-2">
                                                                        <img className="rounded-circle" src="../assets/img/team/2.jpg" alt="" />
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <h6 className="mb-0 link-900 name">Logan M. Butler</h6>
                                                                    </div>
                                                                </a></li>
                                                                <li><a className="d-flex align-items-center text-decoration-none py-1 px-3" href="#!">
                                                                    <div className="avatar avatar-xl me-2">
                                                                        <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <h6 className="mb-0 link-900 name">Rowan Atkinson</h6>
                                                                    </div>
                                                                </a></li>
                                                                <li><a className="d-flex align-items-center text-decoration-none py-1 px-3" href="#!">
                                                                    <div className="avatar avatar-xl me-2">
                                                                        <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <h6 className="mb-0 link-900 name">John Doe</h6>
                                                                    </div>
                                                                </a></li>
                                                                <li><a className="d-flex align-items-center text-decoration-none py-1 px-3" href="#!">
                                                                    <div className="avatar avatar-xl me-2">
                                                                        <img className="rounded-circle" src="../assets/img/team/5.jpg" alt="" />
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <h6 className="mb-0 link-900 name">Emily Rose</h6>
                                                                    </div>
                                                                </a></li>
                                                                <li><a className="d-flex align-items-center text-decoration-none py-1 px-3" href="#!">
                                                                    <div className="avatar avatar-xl me-2">
                                                                        <img className="rounded-circle" src="../assets/img/team/6.jpg" alt="" />
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <h6 className="mb-0 link-900 name">Marry Jane</h6>
                                                                    </div>
                                                                </a></li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <hr className="my-4" />
                                            </div>
                                        </div>
                                        <div className="d-flex"><span className="fa-stack ms-n1 me-3"><i className="fas fa-circle fa-stack-2x text-200"></i><i className="fa-inverse fa-stack-1x text-primary fas fa-tag" data-fa-transform="shrink-2"></i></span>
                                            <div className="flex-1">
                                                <h5 className="mb-2 fs-0">Labels</h5>
                                                <div className="d-flex"><span className="badge me-1 py-2 badge-soft-success">New</span><span className="badge me-1 py-2 badge-soft-primary">Goal</span><span className="badge me-1 py-2 badge-soft-info">Enhancement</span>
                                                    <div className="dropdown dropend"><button className="btn btn-sm btn-secondary px-2 fsp-75 bg-400 border-400 dropdown-toggle dropdown-caret-none" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-plus"></span></button>
                                                        <div className="dropdown-menu">
                                                            <h6 className="dropdown-header py-0 px-3 mb-0">Select Label</h6>
                                                            <div className="dropdown-divider"></div>
                                                            <div className="px-3"><button className="badge-soft-success dropdown-item rounded-1 mb-2" type="button">New</button><button className="badge-soft-primary dropdown-item rounded-1 mb-2" type="button">Goal</button><button className="badge-soft-info dropdown-item rounded-1 mb-2" type="button">Enhancement</button><button className="badge-soft-danger dropdown-item rounded-1 mb-2" type="button">Bug</button><button className="badge-soft-secondary dropdown-item rounded-1 mb-2" type="button">Documentation</button><button className="badge-soft-warning dropdown-item rounded-1 mb-2" type="button">Helper</button></div>
                                                            <div className="dropdown-divider"></div>
                                                            <div className="px-3"><button className="btn btn-sm d-block w-100 btn-outline-secondary border-400">Create Label</button></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="my-4" />
                                            </div>
                                        </div>
                                        <div className="d-flex"><span className="fa-stack ms-n1 me-3"><i className="fas fa-circle fa-stack-2x text-200"></i><i className="fa-inverse fa-stack-1x text-primary fas fa-align-left" data-fa-transform="shrink-2"></i></span>
                                            <div className="flex-1">
                                                <h5 className="mb-2 fs-0">Description</h5>
                                                <p className="text-word-break fs--1">The illustration must match to the contrast of the theme. The illustraion must described the concept of the design. To know more about the theme visit the page. <a href="https://brad.software/brad/v2.0.1/index.php" target="_blank">https://brad.software/brad/v2.0.1/index.php</a></p>
                                                <hr className="my-4" />
                                            </div>
                                        </div>
                                        <div className="d-flex"> <span className="fa-stack ms-n1 me-3"><i className="fas fa-circle fa-stack-2x text-200"></i><i className="fa-inverse fa-stack-1x text-primary fas fa-paperclip" data-fa-transform="shrink-2"></i></span>
                                            <div className="flex-1">
                                                <div className="d-flex justify-content-between mb-2">
                                                    <h5 className="mb-0 fs-0">Attachments</h5>
                                                    <div className="dropdown"><button className="btn btn-brad-default btn-sm dropdown-toggle dropdown-caret-none fs--2" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <span className="fas fa-plus"></span></button>
                                                        <div className="dropdown-menu dropdown-menu-end"><a className="dropdown-item" href="#!">Computer</a><a className="dropdown-item" href="#!">Google Drive</a>
                                                            <div className="dropdown-divider"></div><a className="dropdown-item" href="#!">Attach Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-3"><a className="text-decoration-none me-3" href="../assets/img/kanban/3.jpg" data-gallery="attachment-bg">
                                                    <div className="bg-attachment">
                                                        <div className="bg-holder rounded" style={{backgroundImage: 'https://brad.software/assets/img/kanban/3.jpg'}}></div>
                                                    </div>
                                                </a>
                                                    <div className="flex-1 fs--2">
                                                        <h6 className="mb-1"> <a className="text-decoration-none" href="../assets/img/kanban/3.jpg" data-gallery="attachment-title">final-img.jpg</a></h6><a className="link-600 fw-semi-bold" href="#!">Edit</a><span className="mx-1">|</span><a className="link-600 fw-semi-bold" href="#!">Remove</a>
                                                        <p className="mb-0">Uploaded at 2020-04-18 5:25 pm</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-3"><a className="text-decoration-none me-3" href="../assets/img/kanban/4.jpg" data-gallery="attachment-bg">
                                                    <div className="bg-attachment">
                                                        <div className="bg-holder rounded" style={{backgroundImage: 'https://brad.software/assets/img/kanban/4.jpg'}}></div>
                                                    </div>
                                                </a>
                                                    <div className="flex-1 fs--2">
                                                        <h6 className="mb-1"> <a className="text-decoration-none" href="../assets/img/kanban/4.jpg" data-gallery="attachment-title">picture.png</a></h6><a className="link-600 fw-semi-bold" href="#!">Edit</a><span className="mx-1">|</span><a className="link-600 fw-semi-bold" href="#!">Remove</a>
                                                        <p className="mb-0">Uploaded at 2020-04-20 4:34 pm</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-3"><a className="text-decoration-none me-3" href="#!">
                                                    <div className="bg-attachment"><span className="text-uppercase fw-bold">txt</span></div>
                                                </a>
                                                    <div className="flex-1 fs--2">
                                                        <h6 className="mb-1"> <a className="text-decoration-none" href="#!">sample.txt</a></h6><a className="link-600 fw-semi-bold" href="#!">Edit</a><span className="mx-1">|</span><a className="link-600 fw-semi-bold" href="#!">Remove</a>
                                                        <p className="mb-0">Uploaded at 2020-04-21 2:10 pm</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-3"><a className="text-decoration-none me-3" href="#!">
                                                    <div className="bg-attachment"><span className="text-uppercase fw-bold">pdf</span></div>
                                                </a>
                                                    <div className="flex-1 fs--2">
                                                        <h6 className="mb-1"> <a className="text-decoration-none" href="#!">example.pdf</a></h6><a className="link-600 fw-semi-bold" href="#!">Edit</a><span className="mx-1">|</span><a className="link-600 fw-semi-bold" href="#!">Remove</a>
                                                        <p className="mb-0">Uploaded at 2020-05-02 11:34 am</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center"><a className="text-decoration-none me-3" href="../assets/video/beach.mp4" data-gallery="attachment-bg">
                                                    <div className="bg-attachment">
                                                        <div className="bg-holder rounded" style={{backgroundImage: 'https://brad.software/assets/video/beach.jpg'}}></div>
                                                        <div className="icon-play"><span className="fas fa-play"></span></div>
                                                    </div>
                                                </a>
                                                    <div className="flex-1 fs--2">
                                                        <h6 className="mb-1"> <a className="text-decoration-none" href="../assets/video/beach.mp4" data-gallery="attachment-title">beach.mp4</a></h6><a className="link-600 fw-semi-bold" href="#!">Edit</a><span className="mx-1">|</span><a className="link-600 fw-semi-bold" href="#!">Remove</a>
                                                        <p className="mb-0">Uploaded at 2020-05-10 3:40 pm</p>
                                                    </div>
                                                </div>
                                                <hr className="my-4" />
                                            </div>
                                        </div>
                                        <div className="d-flex"><span className="fa-stack ms-n1 me-3"><i className="fas fa-circle fa-stack-2x text-200"></i><i className="fa-inverse fa-stack-1x text-primary far fa-comment" data-fa-transform="shrink-2"></i></span>
                                            <div className="flex-1">
                                                <h5 className="mb-3 fs-0">Comments</h5>
                                                <div className="d-flex">
                                                    <div className="avatar avatar-l me-2">
                                                        <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                                    </div>
                                                    <div className="flex-1 fs--1">
                                                        <div className="position-relative border rounded-1 mb-3">
                                                            <form><textarea className="form-control border-0 rounded-bottom-0 resize-none" rows={3} contentEditable="true"></textarea>
                                                                <div className="d-flex flex-between-center bg-light rounded-bottom p-2 mt-1"><button className="btn btn-sm btn-primary" type="submit">Save</button>
                                                                    <ul className="list-inline mb-0">
                                                                        <li className="list-inline-item me-1"><a className="link-600 p-2 transition-base rounded" href="#!"><span className="fas fa-paperclip"></span></a></li>
                                                                        <li className="list-inline-item me-1"><a className="link-600 p-2 transition-base rounded" href="#!"><span className="fas fa-at"></span></a></li>
                                                                        <li className="list-inline-item me-1"><a className="link-600 p-2 transition-base rounded" href="#!"><span className="fas fa-image"></span></a></li>
                                                                    </ul>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-3"><a href="../pages/user/profile.html">
                                                    <div className="avatar avatar-l">
                                                        <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                                    </div>
                                                </a>
                                                    <div className="flex-1 ms-2 fs--1">
                                                        <p className="mb-1 bg-200 rounded-3 p-2"><a className="fw-semi-bold" href="../pages/user/profile.html">Rowan </a>This time we should finish the task before the deadline</p><a href="#!">Like</a> &bull; <a href="#!">Reply</a> &bull; 23min
                                                    </div>
                                                </div>
                                                <div className="d-flex"><a href="../pages/user/profile.html">
                                                    <div className="avatar avatar-l">
                                                        <img className="rounded-circle" src="../assets/img/team/1.jpg" alt="" />
                                                    </div>
                                                </a>
                                                    <div className="flex-1 ms-2 fs--1">
                                                        <p className="mb-1 bg-200 rounded-3 p-2"><a className="fw-semi-bold" href="../pages/user/profile.html">Emma </a>We have more task to do</p><a href="#!">Like</a> &bull; <a href="#!">Reply</a> &bull; 2hour
                                                    </div>
                                                </div>
                                                <hr className="my-4" />
                                            </div>
                                        </div>
                                        <div className="d-flex"><span className="fa-stack ms-n1 me-3"><i className="fas fa-circle fa-stack-2x text-200"></i><i className="fa-inverse fa-stack-1x text-primary fas fa-list-ul" data-fa-transform="shrink-2"></i></span>
                                            <div className="flex-1">
                                                <h5 className="mb-3 fs-0">Activity </h5>
                                                <div className="d-flex mb-3"><a href="../pages/user/profile.html">
                                                    <div className="avatar avatar-l">
                                                        <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                                    </div>
                                                </a>
                                                    <div className="flex-1 ms-2 fs--1">
                                                        <p className="mb-0"><a className="fw-semi-bold" href="../pages/user/profile.html">Rowan </a>Added the card</p>
                                                        <div className="fs--2">6 hours ago</div>
                                                    </div>
                                                </div>
                                                <div className="d-flex"><a href="../pages/user/profile.html">
                                                    <div className="avatar avatar-l">
                                                        <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                                                    </div>
                                                </a>
                                                    <div className="flex-1 ms-2 fs--1">
                                                        <p className="mb-0"><a className="fw-semi-bold" href="../pages/user/profile.html">Anna </a>attached final-pic.png to this card</p>
                                                        <div className="fs--2">4 hours ago</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <h6 className="mt-5 mt-lg-0">Add To Card</h6>
                                        <ul className="nav flex-lg-column fs--1">
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"> <span className="fas fa-user me-2"></span>Members</a></li>
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"> <span className="fas fa-tag me-2"></span>Label</a></li>
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"> <span className="fas fa-paperclip me-2"></span>Attachments</a></li>
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"> <span className="fas fa-check me-2"></span>Checklists</a></li>
                                        </ul>
                                        <h6 className="mt-3">Actions</h6>
                                        <ul className="nav flex-lg-column fs--1">
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"> <span className="far fa-copy me-2"> </span>Copy</a></li>
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"> <span className="fas fa-arrow-right me-2"></span>Move</a></li>
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"><span className="fas fa-trash-alt me-2"></span>Remove</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="kanban-modal-label-3" aria-hidden="true" id="kanban-modal-3">
                <div className="modal-dialog modal-lg mt-6" role="document">
                    <div className="modal-content border-0">
                        <div className="position-relative overflow-hidden py-8">
                            <div className="bg-holder rounded-top-lg" style={{backgroundImage: 'https://brad.software/assets/img/kanban/2.jpg'}}></div>
                        </div>
                        <div className="position-absolute top-0 end-0 mt-3 me-3 z-index-1"><button className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close"></button></div>
                        <div className="modal-body p-0">
                            <div className="bg-light rounded-top-lg py-3 px-4">
                                <h4 className="mb-1" id="kanban-modal-label-3">Add a new illustration to the landing page</h4>
                                <p className="fs--2 mb-0">Added by <a className="link-600 fw-semi-bold" href="#!">Antony</a></p>
                            </div>
                            <div className="p-4">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <div className="d-flex"><span className="fa-stack ms-n1 me-3"><i className="fas fa-circle fa-stack-2x text-200"></i><i className="fa-inverse fa-stack-1x text-primary fas fa-user" data-fa-transform="shrink-2"></i></span>
                                            <div className="flex-1">
                                                <h5 className="mb-2 fs-0">Reviewers</h5>
                                                <ul className="nav avatar-group mb-0">
                                                    <li className="nav-item dropdown"></li>
                                                    <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <img className="rounded-circle" src="../assets/img/team/1.jpg" alt="" />
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md px-0 py-3">
                                                            <div className="d-flex align-items-center position-relative px-3">
                                                                <div className="avatar avatar-2xl me-2">
                                                                    <img className="rounded-circle" src="../assets/img/team/1.jpg" alt="" />
                                                                </div>
                                                                <div className="flex-1">
                                                                    <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">Anna Karinina</a></h6>
                                                                    <p className="mb-0 fs--2">Member</p>
                                                                </div>
                                                            </div>
                                                            <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <img className="rounded-circle" src="../assets/img/team/2.jpg" alt="" />
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md px-0 py-3">
                                                            <div className="d-flex align-items-center position-relative px-3">
                                                                <div className="avatar avatar-2xl me-2">
                                                                    <img className="rounded-circle" src="../assets/img/team/2.jpg" alt="" />
                                                                </div>
                                                                <div className="flex-1">
                                                                    <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">Logan M. Butler</a></h6>
                                                                    <p className="mb-0 fs--2">Member</p>
                                                                </div>
                                                            </div>
                                                            <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md px-0 py-3">
                                                            <div className="d-flex align-items-center position-relative px-3">
                                                                <div className="avatar avatar-2xl me-2">
                                                                    <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                                                                </div>
                                                                <div className="flex-1">
                                                                    <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">Rowan Atkinson</a></h6>
                                                                    <p className="mb-0 fs--2">Member</p>
                                                                </div>
                                                            </div>
                                                            <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md px-0 py-3">
                                                            <div className="d-flex align-items-center position-relative px-3">
                                                                <div className="avatar avatar-2xl me-2">
                                                                    <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                                                </div>
                                                                <div className="flex-1">
                                                                    <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">John Doe</a></h6>
                                                                    <p className="mb-0 fs--2">Member</p>
                                                                </div>
                                                            </div>
                                                            <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <img className="rounded-circle" src="../assets/img/team/5.jpg" alt="" />
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md px-0 py-3">
                                                            <div className="d-flex align-items-center position-relative px-3">
                                                                <div className="avatar avatar-2xl me-2">
                                                                    <img className="rounded-circle" src="../assets/img/team/5.jpg" alt="" />
                                                                </div>
                                                                <div className="flex-1">
                                                                    <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">Emily Rose</a></h6>
                                                                    <p className="mb-0 fs--2">Member</p>
                                                                </div>
                                                            </div>
                                                            <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown"><a className="nav-link p-0 dropdown-toggle dropdown-caret-none ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <img className="rounded-circle" src="../assets/img/team/6.jpg" alt="" />
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md px-0 py-3">
                                                            <div className="d-flex align-items-center position-relative px-3">
                                                                <div className="avatar avatar-2xl me-2">
                                                                    <img className="rounded-circle" src="../assets/img/team/6.jpg" alt="" />
                                                                </div>
                                                                <div className="flex-1">
                                                                    <h6 className="mb-0"><a className="stretched-link text-900" href="../pages/user/profile.html">Marry Jane</a></h6>
                                                                    <p className="mb-0 fs--2">Member</p>
                                                                </div>
                                                            </div>
                                                            <hr className="my-2" /><a className="dropdown-item" href="#!">Member Rule</a><a className="dropdown-item text-danger" href="#!">Remove Member</a>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown"><a className="nav-link dropdown-toggle dropdown-caret-none p-0 ms-n1" href="#" role="button" data-bs-toggle="dropdown">
                                                        <div className="avatar avatar-xl">
                                                            <div className="avatar-name rounded-circle avatar-button"><span><span className="fas fa-plus" data-fa-trasnsform="grow-2"></span></span></div>
                                                        </div>
                                                    </a>
                                                        <div className="dropdown-menu dropdown-md" data-list='{"valueNames":["name"]}'>
                                                            <h6 className="dropdown-header py-0 px-3 mb-0">Select Member</h6>
                                                            <div className="dropdown-divider"></div>
                                                            <form className="px-3 mb-2"><input className="form-control form-control-sm search" type="text" placeholder="Search team member" /></form>
                                                            <ul className="list-unstyled list">
                                                                <li><a className="d-flex align-items-center text-decoration-none py-1 px-3" href="#!">
                                                                    <div className="avatar avatar-xl me-2">
                                                                        <img className="rounded-circle" src="../assets/img/team/1.jpg" alt="" />
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <h6 className="mb-0 link-900 name">Anna Karinina</h6>
                                                                    </div>
                                                                </a></li>
                                                                <li><a className="d-flex align-items-center text-decoration-none py-1 px-3" href="#!">
                                                                    <div className="avatar avatar-xl me-2">
                                                                        <img className="rounded-circle" src="../assets/img/team/2.jpg" alt="" />
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <h6 className="mb-0 link-900 name">Logan M. Butler</h6>
                                                                    </div>
                                                                </a></li>
                                                                <li><a className="d-flex align-items-center text-decoration-none py-1 px-3" href="#!">
                                                                    <div className="avatar avatar-xl me-2">
                                                                        <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <h6 className="mb-0 link-900 name">Rowan Atkinson</h6>
                                                                    </div>
                                                                </a></li>
                                                                <li><a className="d-flex align-items-center text-decoration-none py-1 px-3" href="#!">
                                                                    <div className="avatar avatar-xl me-2">
                                                                        <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <h6 className="mb-0 link-900 name">John Doe</h6>
                                                                    </div>
                                                                </a></li>
                                                                <li><a className="d-flex align-items-center text-decoration-none py-1 px-3" href="#!">
                                                                    <div className="avatar avatar-xl me-2">
                                                                        <img className="rounded-circle" src="../assets/img/team/5.jpg" alt="" />
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <h6 className="mb-0 link-900 name">Emily Rose</h6>
                                                                    </div>
                                                                </a></li>
                                                                <li><a className="d-flex align-items-center text-decoration-none py-1 px-3" href="#!">
                                                                    <div className="avatar avatar-xl me-2">
                                                                        <img className="rounded-circle" src="../assets/img/team/6.jpg" alt="" />
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <h6 className="mb-0 link-900 name">Marry Jane</h6>
                                                                    </div>
                                                                </a></li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <hr className="my-4" />
                                            </div>
                                        </div>
                                        <div className="d-flex"><span className="fa-stack ms-n1 me-3"><i className="fas fa-circle fa-stack-2x text-200"></i><i className="fa-inverse fa-stack-1x text-primary fas fa-tag" data-fa-transform="shrink-2"></i></span>
                                            <div className="flex-1">
                                                <h5 className="mb-2 fs-0">Labels</h5>
                                                <div className="d-flex"><span className="badge me-1 py-2 badge-soft-success">New</span><span className="badge me-1 py-2 badge-soft-primary">Goal</span><span className="badge me-1 py-2 badge-soft-info">Enhancement</span>
                                                    <div className="dropdown dropend"><button className="btn btn-sm btn-secondary px-2 fsp-75 bg-400 border-400 dropdown-toggle dropdown-caret-none" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-plus"></span></button>
                                                        <div className="dropdown-menu">
                                                            <h6 className="dropdown-header py-0 px-3 mb-0">Select Label</h6>
                                                            <div className="dropdown-divider"></div>
                                                            <div className="px-3"><button className="badge-soft-success dropdown-item rounded-1 mb-2" type="button">New</button><button className="badge-soft-primary dropdown-item rounded-1 mb-2" type="button">Goal</button><button className="badge-soft-info dropdown-item rounded-1 mb-2" type="button">Enhancement</button><button className="badge-soft-danger dropdown-item rounded-1 mb-2" type="button">Bug</button><button className="badge-soft-secondary dropdown-item rounded-1 mb-2" type="button">Documentation</button><button className="badge-soft-warning dropdown-item rounded-1 mb-2" type="button">Helper</button></div>
                                                            <div className="dropdown-divider"></div>
                                                            <div className="px-3"><button className="btn btn-sm d-block w-100 btn-outline-secondary border-400">Create Label</button></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="my-4" />
                                            </div>
                                        </div>
                                        <div className="d-flex"><span className="fa-stack ms-n1 me-3"><i className="fas fa-circle fa-stack-2x text-200"></i><i className="fa-inverse fa-stack-1x text-primary fas fa-align-left" data-fa-transform="shrink-2"></i></span>
                                            <div className="flex-1">
                                                <h5 className="mb-2 fs-0">Description</h5>
                                                <p className="text-word-break fs--1">The illustration must match to the contrast of the theme. The illustraion must described the concept of the design. To know more about the theme visit the page. <a href="https://brad.software/brad/v2.0.1/index.php" target="_blank">https://brad.software/brad/v2.0.1/index.php</a></p>
                                                <hr className="my-4" />
                                            </div>
                                        </div>
                                        <div className="d-flex"> <span className="fa-stack ms-n1 me-3"><i className="fas fa-circle fa-stack-2x text-200"></i><i className="fa-inverse fa-stack-1x text-primary fas fa-paperclip" data-fa-transform="shrink-2"></i></span>
                                            <div className="flex-1">
                                                <div className="d-flex justify-content-between mb-2">
                                                    <h5 className="mb-0 fs-0">Attachments</h5>
                                                    <div className="dropdown"><button className="btn btn-brad-default btn-sm dropdown-toggle dropdown-caret-none fs--2" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <span className="fas fa-plus"></span></button>
                                                        <div className="dropdown-menu dropdown-menu-end"><a className="dropdown-item" href="#!">Computer</a><a className="dropdown-item" href="#!">Google Drive</a>
                                                            <div className="dropdown-divider"></div><a className="dropdown-item" href="#!">Attach Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-3"><a className="text-decoration-none me-3" href="../assets/img/kanban/3.jpg" data-gallery="attachment-bg">
                                                    <div className="bg-attachment">
                                                        <div className="bg-holder rounded" style={{backgroundImage: 'https://brad.software/assets/img/kanban/3.jpg'}}></div>
                                                    </div>
                                                </a>
                                                    <div className="flex-1 fs--2">
                                                        <h6 className="mb-1"> <a className="text-decoration-none" href="../assets/img/kanban/3.jpg" data-gallery="attachment-title">final-img.jpg</a></h6><a className="link-600 fw-semi-bold" href="#!">Edit</a><span className="mx-1">|</span><a className="link-600 fw-semi-bold" href="#!">Remove</a>
                                                        <p className="mb-0">Uploaded at 2020-04-18 5:25 pm</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-3"><a className="text-decoration-none me-3" href="../assets/img/kanban/4.jpg" data-gallery="attachment-bg">
                                                    <div className="bg-attachment">
                                                        <div className="bg-holder rounded" style={{backgroundImage: 'https://brad.software/assets/img/kanban/4.jpg'}}></div>
                                                    </div>
                                                </a>
                                                    <div className="flex-1 fs--2">
                                                        <h6 className="mb-1"> <a className="text-decoration-none" href="../assets/img/kanban/4.jpg" data-gallery="attachment-title">picture.png</a></h6><a className="link-600 fw-semi-bold" href="#!">Edit</a><span className="mx-1">|</span><a className="link-600 fw-semi-bold" href="#!">Remove</a>
                                                        <p className="mb-0">Uploaded at 2020-04-20 4:34 pm</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-3"><a className="text-decoration-none me-3" href="#!">
                                                    <div className="bg-attachment"><span className="text-uppercase fw-bold">txt</span></div>
                                                </a>
                                                    <div className="flex-1 fs--2">
                                                        <h6 className="mb-1"> <a className="text-decoration-none" href="#!">sample.txt</a></h6><a className="link-600 fw-semi-bold" href="#!">Edit</a><span className="mx-1">|</span><a className="link-600 fw-semi-bold" href="#!">Remove</a>
                                                        <p className="mb-0">Uploaded at 2020-04-21 2:10 pm</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-3"><a className="text-decoration-none me-3" href="#!">
                                                    <div className="bg-attachment"><span className="text-uppercase fw-bold">pdf</span></div>
                                                </a>
                                                    <div className="flex-1 fs--2">
                                                        <h6 className="mb-1"> <a className="text-decoration-none" href="#!">example.pdf</a></h6><a className="link-600 fw-semi-bold" href="#!">Edit</a><span className="mx-1">|</span><a className="link-600 fw-semi-bold" href="#!">Remove</a>
                                                        <p className="mb-0">Uploaded at 2020-05-02 11:34 am</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center"><a className="text-decoration-none me-3" href="../assets/video/beach.mp4" data-gallery="attachment-bg">
                                                    <div className="bg-attachment">
                                                        <div className="bg-holder rounded" style={{backgroundImage: 'https://brad.software/assets/video/beach.jpg'}}></div>
                                                        <div className="icon-play"><span className="fas fa-play"></span></div>
                                                    </div>
                                                </a>
                                                    <div className="flex-1 fs--2">
                                                        <h6 className="mb-1"> <a className="text-decoration-none" href="../assets/video/beach.mp4" data-gallery="attachment-title">beach.mp4</a></h6><a className="link-600 fw-semi-bold" href="#!">Edit</a><span className="mx-1">|</span><a className="link-600 fw-semi-bold" href="#!">Remove</a>
                                                        <p className="mb-0">Uploaded at 2020-05-10 3:40 pm</p>
                                                    </div>
                                                </div>
                                                <hr className="my-4" />
                                            </div>
                                        </div>
                                        <div className="d-flex"><span className="fa-stack ms-n1 me-3"><i className="fas fa-circle fa-stack-2x text-200"></i><i className="fa-inverse fa-stack-1x text-primary far fa-comment" data-fa-transform="shrink-2"></i></span>
                                            <div className="flex-1">
                                                <h5 className="mb-3 fs-0">Comments</h5>
                                                <div className="d-flex">
                                                    <div className="avatar avatar-l me-2">
                                                        <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                                    </div>
                                                    <div className="flex-1 fs--1">
                                                        <div className="position-relative border rounded-1 mb-3">
                                                            <form><textarea className="form-control border-0 rounded-bottom-0 resize-none" rows={3} contentEditable="true"></textarea>
                                                                <div className="d-flex flex-between-center bg-light rounded-bottom p-2 mt-1"><button className="btn btn-sm btn-primary" type="submit">Save</button>
                                                                    <ul className="list-inline mb-0">
                                                                        <li className="list-inline-item me-1"><a className="link-600 p-2 transition-base rounded" href="#!"><span className="fas fa-paperclip"></span></a></li>
                                                                        <li className="list-inline-item me-1"><a className="link-600 p-2 transition-base rounded" href="#!"><span className="fas fa-at"></span></a></li>
                                                                        <li className="list-inline-item me-1"><a className="link-600 p-2 transition-base rounded" href="#!"><span className="fas fa-image"></span></a></li>
                                                                    </ul>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-3"><a href="../pages/user/profile.html">
                                                    <div className="avatar avatar-l">
                                                        <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                                    </div>
                                                </a>
                                                    <div className="flex-1 ms-2 fs--1">
                                                        <p className="mb-1 bg-200 rounded-3 p-2"><a className="fw-semi-bold" href="../pages/user/profile.html">Rowan </a>This time we should finish the task before the deadline</p><a href="#!">Like</a> &bull; <a href="#!">Reply</a> &bull; 23min
                                                    </div>
                                                </div>
                                                <div className="d-flex"><a href="../pages/user/profile.html">
                                                    <div className="avatar avatar-l">
                                                        <img className="rounded-circle" src="../assets/img/team/1.jpg" alt="" />
                                                    </div>
                                                </a>
                                                    <div className="flex-1 ms-2 fs--1">
                                                        <p className="mb-1 bg-200 rounded-3 p-2"><a className="fw-semi-bold" href="../pages/user/profile.html">Emma </a>We have more task to do</p><a href="#!">Like</a> &bull; <a href="#!">Reply</a> &bull; 2hour
                                                    </div>
                                                </div>
                                                <hr className="my-4" />
                                            </div>
                                        </div>
                                        <div className="d-flex"><span className="fa-stack ms-n1 me-3"><i className="fas fa-circle fa-stack-2x text-200"></i><i className="fa-inverse fa-stack-1x text-primary fas fa-list-ul" data-fa-transform="shrink-2"></i></span>
                                            <div className="flex-1">
                                                <h5 className="mb-3 fs-0">Activity </h5>
                                                <div className="d-flex mb-3"><a href="../pages/user/profile.html">
                                                    <div className="avatar avatar-l">
                                                        <img className="rounded-circle" src="../assets/img/team/4.jpg" alt="" />
                                                    </div>
                                                </a>
                                                    <div className="flex-1 ms-2 fs--1">
                                                        <p className="mb-0"><a className="fw-semi-bold" href="../pages/user/profile.html">Rowan </a>Added the card</p>
                                                        <div className="fs--2">6 hours ago</div>
                                                    </div>
                                                </div>
                                                <div className="d-flex"><a href="../pages/user/profile.html">
                                                    <div className="avatar avatar-l">
                                                        <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                                                    </div>
                                                </a>
                                                    <div className="flex-1 ms-2 fs--1">
                                                        <p className="mb-0"><a className="fw-semi-bold" href="../pages/user/profile.html">Anna </a>attached final-pic.png to this card</p>
                                                        <div className="fs--2">4 hours ago</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <h6 className="mt-5 mt-lg-0">Add To Card</h6>
                                        <ul className="nav flex-lg-column fs--1">
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"> <span className="fas fa-user me-2"></span>Members</a></li>
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"> <span className="fas fa-tag me-2"></span>Label</a></li>
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"> <span className="fas fa-paperclip me-2"></span>Attachments</a></li>
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"> <span className="fas fa-check me-2"></span>Checklists</a></li>
                                        </ul>
                                        <h6 className="mt-3">Actions</h6>
                                        <ul className="nav flex-lg-column fs--1">
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"> <span className="far fa-copy me-2"> </span>Copy</a></li>
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"> <span className="fas fa-arrow-right me-2"></span>Move</a></li>
                                            <li className="nav-item me-2 me-lg-0"><a className="nav-link nav-link-card-details" href="#!"><span className="fas fa-trash-alt me-2"></span>Remove</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Projects