const messages:any = {
// Websocket
    // Connection
    connectionError: {
        message: 'Error connecting to Websocket.'
    },
    connectionReconnect: {
        message: 'Websocket connection closed, attempting to reconnect...'
    },
    connectionClose: {
        message: 'Websocket reconnection failed; connection closed.'
    },
    connectionOpen: {
        message: 'Successfully connected to Websocket.'
    },
    // Messages
    incomingMessage: {
        message: 'Incoming websocket message...'
    },
// Auth Messages
    // Login
    authLoginBegin: {
        message: 'Processing login...'
    },
    authLoginFail: {
        message: 'Failed to login; likely incorrect account credentials.'
    },
    authLoginSuccess: {
        message: 'Successfully logged in... redirecting to root.'
    },
    // Register
    authRegisterBegin: {
        message: 'Processing registration...'
    },
    authRegisterPasswords: {
        message: 'Passwords do not match; please try again.'
    },
    authRegisterFail: {
        message: 'Failed to create account...'
    },
    authRegisterSuccess: {
        message: 'Successfully created account... redirecting to login.'
    },
}

export default messages