import messages from './messages'

// Logging functions
const text = (text:string) => {
    console.log('[BRAD] '+ messages[text].message +'')
}
const warn = (text:string) => {
    console.warn('[BRAD Warning] '+ messages[text].message +'')
}
const error = (text:string) => {
    console.error('[BRAD Error] '+ messages[text].message +'')
}
const custom = (text:string) => {
    console.log(text)
}

const logTypes = {
    text,
    warn,
    error,
    custom
}
export default logTypes