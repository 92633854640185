import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <>
            <nav className="navbar navbar-standard navbar-expand-lg fixed-top" data-navbar-darken-on-scroll="data-navbar-darken-on-scroll">
                <div className="container">
                    <Link className="navbar-brand" to="/home">
                        <div className="d-flex align-items-center py-3">
                            <img className="me-2" src="https://brad.software/assets/img/icons/spot-illustrations/brad-full.png" alt="" width="150" />
                        </div>
                    </Link>
                    <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarStandard" aria-controls="navbarStandard" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                    <div className="collapse navbar-collapse scrollbar" id="navbarStandard">
                        <ul className="navbar-nav" data-top-nav-dropdowns="data-top-nav-dropdowns">
                            {/*<li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dashboards">Dashboard</a>
                                <div className="dropdown-menu dropdown-menu-card border-0 mt-0" aria-labelledby="dashboards">
                                    <div className="bg-white dark__bg-1000 rounded-3 py-2">
                                        <a className="dropdown-item link-600 fw-medium" href="index">Default</a>
                                        <a className="dropdown-item link-600 fw-medium" href="dashboard/analytics.html">Analytics</a>
                                        <a className="dropdown-item link-600 fw-medium" href="dashboard/crm.html">CRM</a>
                                        <a className="dropdown-item link-600 fw-medium" href="dashboard/e-commerce.html">E commerce</a>
                                        <a className="dropdown-item link-600 fw-medium" href="dashboard/project-management.html">Management</a>
                                        <a className="dropdown-item link-600 fw-medium" href="dashboard/saas.html">SaaS</a></div>
                                </div>
                            </li>*/}
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="pagess">Industries</a>
                                <div className="dropdown-menu dropdown-menu-card border-0 mt-0" aria-labelledby="pagess">
                                    <div className="card navbar-card-pages shadow-none dark__bg-1000">
                                        <div className="card-body scrollbar max-h-dropdown">
                                            <img className="img-dropdown" src="https://brad.software/assets/img/icons/spot-illustrations/authentication-corner.png" width="130" alt="" style={{userSelect: 'none', zIndex: '99'}} />
                                            <div className="row">
                                                <div className="col-6 col-xxl-3">
                                                    <div className="nav flex-column">
                                                        <p className="nav-link text-700 mb-0 fw-bold">Enterprise</p>
                                                        <Link className="nav-link py-1 link-600 fw-medium" to="/enterprise/crm">CRM Systems</Link>
                                                        <Link className="nav-link py-1 link-600 fw-medium" to="/enterprise/cdn">CDN Systems</Link>
                                                        <Link className="nav-link py-1 link-600 fw-medium" to="/enterprise/bam">BAM Systems</Link>
                                                        <Link className="nav-link py-1 link-600 fw-medium" to="/enterprise/cloud-hosting">Cloud Hosting</Link>
                                                        <Link className="nav-link py-1 link-600 fw-medium" to="/enterprise/domains">Domains</Link>
                                                        <Link className="nav-link py-1 link-600 fw-medium" to="/enterprise/accessibility">Accessibility</Link>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-xxl-3">
                                                    <div className="nav flex-column">
                                                        <p className="nav-link text-700 mb-0 fw-bold">Finance</p>
                                                        <Link className="nav-link py-1 link-600 fw-medium" to="/finance/appraisals">Appraisals</Link>
                                                        <Link className="nav-link py-1 link-600 fw-medium" to="/finance/payments">Payments</Link>
                                                        <Link className="nav-link py-1 link-600 fw-medium" to="/finance/financing">Financing</Link>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-xxl-3">
                                                    <div className="nav flex-column">
                                                        <p className="nav-link text-700 mb-0 fw-bold">Sales</p>
                                                        <Link className="nav-link py-1 link-600 fw-medium" to="/sales/auctions">Auctions</Link>
                                                        <Link className="nav-link py-1 link-600 fw-medium" to="/sales/dealers">Dealers</Link>
                                                        <Link className="nav-link py-1 link-600 fw-medium" to="/sales/online">Online</Link>
                                                        <Link className="nav-link py-1 link-600 fw-medium" to="/sales/retail">Retail</Link>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-xxl-3">
                                                    <div className="nav flex-column">
                                                        <p className="nav-link text-700 mb-0 fw-bold">Marketing</p>
                                                        <Link className="nav-link py-1 link-600 fw-medium" to="/marketing/advertising" style={{zIndex: '100'}}>Advertising</Link>
                                                        <Link className="nav-link py-1 link-600 fw-medium" to="/marketing/brand-identity" style={{zIndex: '100'}}>Brand & Identity</Link>
                                                        <Link className="nav-link py-1 link-600 fw-medium" to="/marketing/whitelabel" style={{zIndex: '100'}}>Whitelabel</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="abouts">About</a>
                                <div className="dropdown-menu dropdown-menu-card border-0 mt-0" aria-labelledby="abouts">
                                    <div className="bg-white dark__bg-1000 rounded-3 py-2">
                                        <Link className="dropdown-item link-600 fw-medium" to="/about/mission">Mission</Link>
                                        <Link className="dropdown-item link-600 fw-medium" to="/about/team">Team</Link>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="abouts">Business</a>
                                <div className="dropdown-menu dropdown-menu-card border-0 mt-0" aria-labelledby="abouts">
                                    <div className="bg-white dark__bg-1000 rounded-3 py-2">
                                        <Link className="dropdown-item link-600 fw-medium" to="/business/our-brands">Our Brands</Link>
                                        {/*<a className="dropdown-item link-600 fw-medium" href="https://brad.software/business/partners">Partners</a>*/}
                                        <Link className="dropdown-item link-600 fw-medium" to="/business/affiliates">Affiliates</Link>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="abouts">Support</a>
                                <div className="dropdown-menu dropdown-menu-card border-0 mt-0" aria-labelledby="abouts">
                                    <div className="bg-white dark__bg-1000 rounded-3 py-2">
                                        <Link className="dropdown-item link-600 fw-medium" to="/support/">Official Support</Link>
                                        <Link className="dropdown-item link-600 fw-medium" to="/support/documentation">Documentation</Link>
                                        <Link className="dropdown-item link-600 fw-medium" to="/support/community">Community</Link>
                                        <Link className="dropdown-item link-600 fw-medium" to="/support/knowledgebase">Knowledgebase</Link>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul className="navbar-nav ms-auto">
                            {/*<li className="nav-item"><a className="nav-link" href="index"><span className="d-none d-lg-inline-block" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Dashboard"><span className="fas fa-chart-pie"></span></span><span className="d-lg-none">Dashboard</span></a></li>*/}
                            
                            {/* NEED TO COME BACK THROUGH AND ADD AUTH CODE  */}
                            <li className="nav-item">
                                <Link className="nav-link" to="/auth/login">Login</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/auth/register">Register</Link>
                            </li>
                            {/*<li className="nav-item"><a className="nav-link" href="https://brad.software/pricing">Pricing</a></li>*/}
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header