const Default = () => {
    return (
        <>
            <section className="light">
                <div className="bg-holder overlay" style={{backgroundImage: 'url(https://brad.software/assets/img/generic/dots-new.png)', backgroundPosition: 'center top'}}></div>
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-8">
                            <p className="fs-3 fs-sm-4 text-white">Let us elevate your business to the next level, and join us today to experience the ease of running your business from your fingertips.</p>
                            <button className="btn btn-light border-2 rounded-pill btn-lg mt-4 fs-0 py-2" type="button">Get started with B.R.A.D.</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Default